import AuthLayout from '@/components/AuthLayout'
import BodyLink from "@/components/body_link";
import Body from "@/components/body";

function page(path) {
    return () => import(/* webpackChunkName: '' */ `@/pages/${path}`).then(m => m.default || m)
}

const routes = [
    { path: '/', name: 'default', redirect: { name: 'login' }},
    {
        path: '/',
        component: Body,
        children: [
            { name: 'home', path: 'dashboard', component: page('dashboard/index.vue'), meta: { title: 'Dashboard', requiresAuth: true } },
            { name: 'profile', path: 'profile', component: page('profile.vue'), meta: { title: 'Profile', requiresAuth: true } },

            // Master Data
            { path: 'master-data/institutes', name: 'master-data-institutes', component: page('master_data/institutes.vue') },
            { path: 'master-data/privileges', name: 'master-data-privileges', component: page('master_data/permissions.vue') },
            { path: 'master-data/privileges/{:pId}/edit', name: 'master-data-privileges-edit', props: true, component: page('master_data/permissions/list.vue'), meta: { requiresWebmin: true,  } },
            { path: 'master-data/streams', name: 'master-data.streams', component: page('master_data/streams.vue') },
            { path: 'master-data/subjects', name: 'master-data.subjects', component: page('master_data/subjects.vue') },
            { path: 'master-data/class-types', name: 'master-data.class-types', component: page('master_data/class_types.vue') },
            { path: 'master-data/schedule-types', name: 'master-data.schedule-types', component: page('master_data/schedule_types.vue') },
            { path: 'master-data/payment-types', name: 'master-data.payment-types', component: page('master_data/payment_types.vue') },
            { path: 'master-data/free-card-types', name: 'master-data.free-card-types', component: page('master_data/free_card_types.vue') },
            { path: 'master-data/user-roles', name: 'master-data.user-roles', component: page('master_data/roles.vue') },
            { path: 'master-data/user-privileges', name: 'master-data.user-privileges', component: page('master_data/permissions.vue') },
            { path: 'master-data/roles', name: 'master-data.roles', component: page('master_data/roles.vue') },
            { path: 'master-data/towns', name: 'master-data.towns', component: page('master_data/towns.vue') },

            { name: 'users-students', path: 'users/students', component: page('users/index.vue'), props: { has_roles: ['student'] }, meta: { title: 'Students | Users', requiresAuth: true } },
            { name: 'users-teachers', path: 'users/teachers', component: page('users/index.vue'), props: { has_roles: ['teacher'] }, meta: { title: 'Teachers | Users', requiresAuth: true } },
            { name: 'users-staff', path: 'users/staff', component: page('users/index.vue'), props: { has_roles: ['developer','admin','cashier', 'manager', 'accountant', 'class marker'] }, meta: { title: 'Staff | Users', requiresAuth: true } },
            { name: 'users-index', path: 'users', component: page('users/index.vue'), props: { has_roles: [] }, meta: { title: 'Users', requiresAuth: true } },
            { name: 'users-create', path: 'users-create', component: page('users/create.vue'), props: { has_roles: [] }, meta: { title: 'New User', requiresAuth: true } },
            { name: 'users-manage', path: 'users/:userId/edit', component: page('users/edit.vue'), meta: { title: 'Manage User', requiresAuth: true } },
            { name: 'users-view', path: 'users/:userId', component: page('users/show.vue'), meta: { title: 'View User', requiresAuth: true } },
            { name: 'classrooms', path: 'classrooms', component: page('classrooms.vue'), meta: { title: 'Classrooms', requiresAuth: true } },
            { name: 'classes', path: 'classes', component: page('classes.vue'), meta: { title: 'Classes', requiresAuth: true } },
            { name: 'class-schedules', path: 'class-schedules', component: page('class_schedules.vue'), meta: { title: 'Classes Schedules', requiresAuth: true } },
            { name: 'class-registry', path: 'class-registry', component: page('class_registry.vue'), meta: { title: 'Classes Registry', requiresAuth: true } },


            { name: 'exemptions', path: 'exemptions', component: page('exemptions.vue'), meta: { title: 'Exemptions', requiresAuth: true } },
            { name: 'free-cards', path: 'free-cards', component: page('free_cards.vue'), meta: { title: 'Free Cards', requiresAuth: true } },
            { name: 'attendance', path: 'attendance', component: page('attendance.vue'), meta: { title: 'Attendance', requiresAuth: true } },
            { name: 'payments', path: 'payments', component: page('payments.vue'), meta: { title: 'Payments', requiresAuth: true } },
            { name: 'invoices', path: 'invoices', component: page('invoices.vue'), meta: { title: 'Invoices', requiresAuth: true } },

            // { name: 'settlements', path: 'settlements', component: page('settlements/index.vue'), meta: { title: 'Settlements', requiresAuth: true } },
            // { name: 'settlements-index', path: 'settlements-index', component: page('settlements/index.vue'), meta: { title: 'Settlements', requiresAuth: true } },
            // { name: 'settlements-view', path: 'settlements/:settlementId', component: page('settlements/show.vue'), meta: { title: 'Settlement Summary', requiresAuth: true } },
            { name: 'settlements', path: 'settlements', component: page('settlements.vue'), meta: { title: 'Settlements', requiresAuth: true } },

            { name: 'mark-attendance', path: 'mark-attendance', component: page('mark_attendance.vue'), meta: { title: 'Mark Attendance', requiresAuth: true } },

            { name: 'student-cards-generate', path: 'student-cards-generate', component: page('student_cards/generate.vue'), meta: { title: 'Generate Student Cards', requiresAuth: true } },
            { name: 'student-cards-activate', path: 'student-cards-activate', component: page('student_cards/activate.vue'), meta: { title: 'Activate Student Cards', requiresAuth: true } },
            { name: 'student-cards-view', path: 'student-cards-view', component: page('student_cards/index.vue'), meta: { title: 'Student Cards', requiresAuth: true } },

            {
                path: 'reports',
                component: BodyLink,
                children: [
                    { name: 'student-attendance-report', path: 'student-attendance-report', component: page('reports/student_attendance.vue'), meta: { title: 'Student Attendance Report', requiresAuth: true } },
                    { name: 'student-payment-report', path: 'student-payment-report', component: page('reports/student_payments.vue'), meta: { title: 'Student Payment Report', requiresAuth: true } },
                    { name: 'teacher-settlement-reports', path: 'teacher-settlement-reports', component: page('reports/settlements/index.vue'), meta: { title: 'Teacher Settlement Reports', requiresAuth: true } },
                    { name: 'teacher-settlement-report-view', path: 'teacher-settlement-reports/:settlementId', component: page('reports/settlements/show.vue'), meta: { title: 'Teacher Settlement Report', requiresAuth: true } },
                    { name: 'teacher-settlement-report', path: 'teacher-settlement-report', component: page('reports/teacher_payments.vue'), meta: { title: 'Teacher Settlement Report', requiresAuth: true } },
                    { name: 'classroom-logins-report', path: 'classroom-logins-report', component: page('reports/classroom_logins.vue'), meta: { title: 'Classroom Login Report', requiresAuth: true } },
                    { name: 'lecturer-class-report', path: 'lecturer-class-report', component: page('reports/lecturer_class_report.vue'), meta: { title: 'Lecturer Class Report', requiresAuth: true } },
                    { name: 'teacher-summary-report', path: 'teacher-summary-report', component: page('reports/teacher_summary_report.vue'), meta: { title: 'Teacher Summary', requiresAuth: true } },
                ]
            },

            {
                path: 'zoom',
                component: BodyLink,
                children: [
                    { name: 'zoom-configurations', path: 'configurations', component: page('zoom/configurations.vue'), meta: { title: 'Zoom Configurations', requiresAuth: true } },
                    { name: 'zoom-meetings', path: 'meetings', component: page('zoom/meeting_list.vue'), meta: { title: 'Zoom Meetings', requiresAuth: true } },
               ]
            }

        ]
    },
    {
        path: '/',
        component: AuthLayout,
        children: [
            {
                name: 'login', path: 'login', component: page('auth/login.vue'),
                meta: { title: 'Login', requiresVisitor: true }
            },
            {
                name: 'reset-password', path: 'reset-password', component: page('auth/reset_password.vue'),
                meta: { title: 'Reset Password', requiresVisitor: true }
            },
            {
                name: 'register', path: 'register', component: page('auth/register.vue'),
                meta: { title: 'Register', requiresVisitor: true }
            },
            {
                name: 'registered', path: 'register/complete', component: page('auth/registered.vue'),
                meta: { title: 'Registered', requiresVisitor: true }
            }
        ]
    }
];

export default routes
