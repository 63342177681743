<template>
  <div class="">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "AuthLayout"
}
</script>

<style scoped>
  /*body, html {*/
  /*  background-image: url("/assets/images/bgpattern.png");*/
  /*  background-repeat: repeat;*/
  /*}*/
</style>
