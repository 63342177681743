import Vue from "vue";
import Vuex from "vuex";
import 'es6-promise/auto'
import layout from './modules/layout'
import menu from './modules/menu'


import auth from './modules/auth'
import select from './modules/select'

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production'



export const store = new Vuex.Store({
    modules: {
        layout, menu, auth, select
    },
    strict: false
});

