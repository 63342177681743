import axios from "@/plugins/axios";
import * as types from '@/store/mutation-types'

const state = {
    roles: JSON.stringify([]),
    permissions: JSON.stringify([]),
    subjects: JSON.stringify([]),
    streams: JSON.stringify([]),
    institutes: JSON.stringify([]),

    teachers: JSON.stringify([]),
    cashiers: JSON.stringify([]),

    classrooms: JSON.stringify([]),
    class_types: JSON.stringify([]),
    payment_types: JSON.stringify([]),
    schedule_types: JSON.stringify([]),
    class_details: JSON.stringify([]),
    free_card_types: JSON.stringify([]),
    towns: JSON.stringify([]),

    zoom_configurations: JSON.stringify([]),
    zoom_meetings: JSON.stringify([])
}

const getters = {
    roles: state => JSON.parse(state.roles),
    permissions: state => JSON.parse(state.permissions),
    institutes: state => JSON.parse(state.institutes),
    streams: state => JSON.parse(state.streams),
    subjects: state => JSON.parse(state.subjects),

    teachers: state => JSON.parse(state.teachers),
    cashiers: state => JSON.parse(state.cashiers),

    classrooms: state => JSON.parse(state.classrooms),
    class_types: state => JSON.parse(state.class_types),
    payment_types: state => JSON.parse(state.payment_types),
    schedule_types: state => JSON.parse(state.schedule_types),
    class_details: state => JSON.parse(state.class_details),
    free_card_types: state => JSON.parse(state.free_card_types),
    towns: state => JSON.parse(state.towns),

    zoom_configurations: state => JSON.parse(state.zoom_configurations),
    zoom_meetings: state => JSON.parse(state.zoom_meetings),
}

const mutations = {

    [types.FETCH_RECORDS_SUCCESS]: (state, payload) => {
        switch (payload.id) {
            case 'institutes':
                state.institutes = JSON.stringify(payload.data)
                break;
            case 'roles':
                state.roles = JSON.stringify(payload.data)
                break;
            case 'permissions':
                state.permissions = JSON.stringify(payload.data)
                break;
            case 'streams':
                state.streams = JSON.stringify(payload.data)
                break;
            case 'subjects':
                state.subjects = JSON.stringify(payload.data)
                break;

            case 'teachers':
                state.teachers = JSON.stringify(payload.data)
                break;
            case 'cashiers':
                state.cashiers = JSON.stringify(payload.data)
                break;

            case 'classrooms':
                state.classrooms = JSON.stringify(payload.data)
                break;
            case 'class-types':
                state.class_types = JSON.stringify(payload.data)
                break;
            case 'payment-types':
                state.payment_types = JSON.stringify(payload.data)
                break;
            case 'schedule-types':
                state.schedule_types = JSON.stringify(payload.data)
                break;
            case 'class-details':
                state.class_details = JSON.stringify(payload.data)
                break;
            case 'free-card-types':
                state.free_card_types = JSON.stringify(payload.data)
                break;
            case 'towns':
                state.towns = JSON.stringify(payload.data)
                break;
            case 'zoom-configurations':
                state.zoom_configurations = JSON.stringify(payload.data)
                break;
            case 'zoom-meetings':
                state.zoom_meetings = JSON.stringify(payload.data)
                break;
            default:
                break;
        }
    },

    [types.FETCH_RECORDS_FAILURE]: (state, id) => {
        const default_data = JSON.stringify([]);
        switch (id) {
            case 'institutes':
                state.institutes = default_data
                break;
            case 'roles':
                state.roles = default_data
                break;
            case 'permissions':
                state.permissions = default_data
                break;
            case 'streams':
                state.streams = default_data
                break;
            case 'subjects':
                state.subjects = default_data
                break;

            case 'teachers':
                state.teachers = default_data
                break;
            case 'cashiers':
                state.cashiers = default_data
                break;

            case 'classrooms':
                state.classrooms = default_data
                break;
            case 'class-types':
                state.class_types = default_data
                break;
            case 'payment-types':
                state.payment_types = default_data
                break;
            case 'schedule-types':
                state.schedule_types = default_data
                break;
            case 'class-details':
                state.class_details = default_data
                break;
            case 'free-card-types':
                state.free_card_types = default_data
                break;
            case 'towns':
                state.towns = default_data
                break;
            case 'zoom-configurations':
                state.zoom_configurations = default_data
                break;
            case 'zoom-meetings':
                state.zoom_meetings = default_data
                break;
            default:
                break;
        }
    }

}

const actions = {

    async fetchRecords({ commit }, payload) {
        try {
            let {data} = await axios.get('/api/backend/select/' + payload.id, {
                params: payload.params || null
            })
            commit(types.FETCH_RECORDS_SUCCESS, {
                id: payload.id,
                data: data.data
            })
        } catch (e) {
            console.log(e)
            commit(types.FETCH_RECORDS_FAILURE, payload.id)
        }
    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
