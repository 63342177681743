import * as types from '@/store/mutation-types'
import axios from "@/plugins/axios";

const state = {
    user: null,
    token: localStorage.getItem('token') || ''
}

const getters = {
    user: state => JSON.parse(state.user),
    token: state => state.token,
    authenticated: state => state.user !== null
}

const mutations = {

    [types.FETCH_AUTH_USER_SUCCESS]: (state, payload) => {
        state.user = JSON.stringify(payload)
    },

    ['FETCH_AUTH_USER_FAILURE']: (state) => {
        state.user = null
    }

}

const actions = {

    async register(payload) {
        try {
            return await axios.post('/api/register', payload)
        } catch (e) {
            return e
        }
    },

    async login({ commit }, payload)  {
        try {
            let { data } = await axios.post('/api/login', payload)

            if(data.success) {
                let token = data.token
                localStorage.setItem('token', token)
                localStorage.setItem('user', JSON.stringify(data.logged_in_user))
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                commit(types.FETCH_AUTH_USER_SUCCESS, data.logged_in_user)
            }
            return data
        } catch (e) {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            delete axios.defaults.headers.common['Authorization']
            commit(types.FETCH_AUTH_USER_FAILURE)
            //console.log(e.message)
            return e
        }
    },

    async logout({ commit }) {
        try {
            await axios.post('/api/logout')
            localStorage.clear()
            delete axios.defaults.headers.common['Authorization']
            commit(types.FETCH_AUTH_USER_FAILURE)
            return true
        } catch (e) {
            if (localStorage.getItem('token')) {
                localStorage.removeItem('token')
            }
            //console.log(e.message)
            return false
        }
    },

    async getUser({ commit }) {
        try {
            let { data } = await axios.get('/api/user')
            commit(types.FETCH_AUTH_USER_SUCCESS, data)
            return true
        } catch (e) {
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
            localStorage.removeItem('cart')
            commit(types.FETCH_AUTH_USER_FAILURE)
            //console.log(e.message)
            return false
        }
    },

    async updateProfile({ commit }, payload) {
        let { data } = await axios.patch('/api/update-profile', payload)
        commit(types.FETCH_AUTH_USER_SUCCESS, data.data)
        return data
    },

    async sendOTP(payload) {
        try {
            let { data } = await axios.post('/api/send-signup-otp', payload)
            return data;
        } catch (e) {
            console.log(e.message)
            return false;
        }
    },

    async sendPwdResetOTP(payload) {
        try {
            return await axios.post('/api/send-reset-otp', payload)
        } catch (e) {
            console.log(e.message)
            return false;
        }
    },

    async resetPWD(payload) {
        try {
            return await axios.post('/api/reset-password', payload)
        } catch (e) {
            console.log(e.message)
            return false
        }
    }
}

export default {
    namespaced: true,
    mutations,
    actions,
    getters,
    state
}
