import Vue from 'vue'
import axios from "@/plugins/axios";
import routes from './routes'
import Router from "vue-router";


Vue.use(Router)


const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

function hasRolePermissions(item) {
  let hasPerms = false
  const CurrentUser = localStorage.getItem('user') || null;
  if (item.perms) {
    CurrentUser.roles.forEach((role) => {
      role.permissions.forEach((perms) => {
        if (item.perms.includes(perms.name)) {
          hasPerms = true
        }
      })
    })
    return hasPerms
  }
  return true
}

router.beforeEach((to, from, next) => {
  if(to.meta.title)document.title = to.meta.title + " | Gurumandala Backend";
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.name !== 'login' && to.name !== 'register' && to.name !== 'reset-password' && !localStorage.getItem('token')) {
      next({
        name: 'login',
        query: { redirect: to.fullPath }
      })
    } else {
      if(hasRolePermissions(to)) {
        next()
      } else {
        next({ name: 'home' })
      }
    }
  } else if(to.matched.some(record => record.meta.requiresVisitor)) {
    if(localStorage.getItem('token')) {
      next({ name: 'home' })
    } else {
      next()
    }
  } else {
    next()
  }

  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if(to.name !== 'login' && to.name !== 'register' && to.name !== 'reset-password' && !localStorage.getItem('authenticated')) {
  //     next({
  //       path: '/login',
  //       query: { redirect: to.fullPath }
  //     })
  //   } else {
  //     next()
  //   }
  // } else if(to.matched.some(record => record.meta.requiresVisitor)) {
  //   if(localStorage.getItem('authenticated')) {
  //     next()
  //     // next({
  //     //   name: 'home'
  //     // })
  //   } else {
  //     next()
  //   }
  // } else{
  //   next()
  // }






// //   localStorage.setItem('user', JSON.stringify({id: 2}))
// //   //localStorage.removeItem('user')
// //   const CurrentUser = localStorage.getItem('user') || null;
// //   console.log(to); console.log((CurrentUser && to.meta.requiresAuth===true))
// //   const  path = ['/login','/register'];
// //
// //   if (path.includes(to.path) || to.path === "/callback" || CurrentUser){
//     return next();
// //   }
// //   if(path.includes(to.path) && CurrentUser) {
// //     return next('/dashboard')
// //   }
// //   next('/login')

});


export default router
