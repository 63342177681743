import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import router from './router'
import Breadcrumbs from './components/bread_crumbs'
import { store } from './store';
import VueFeather from 'vue-feather';
import '@/plugins'


// Import Theme scss
import './assets/scss/app.scss'

// import axios from "axios";
// axios.interceptors.request.use(req => {
//   req.headers.authorization = 'Bearer ' + localStorage.getItem('token') || '';
//   return req;
// });



Vue.use(VueSweetalert2);
Vue.use(VueFeather);
Vue.use(BootstrapVue)
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.config.productionTip = false
Vue.config.devtools = false
window.eventBus = new Vue();


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
