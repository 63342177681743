// auth.js
export const REGISTER = 'REGISTER'
export const LOGOUT = 'LOGOUT'
export const LOGIN = 'LOGIN'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_AUTH_USER = 'FETCH_AUTH_USER'
export const FETCH_AUTH_USER_SUCCESS = 'FETCH_AUTH_USER_SUCCESS'
export const FETCH_AUTH_USER_FAILURE = 'FETCH_AUTH_USER_FAILURE'
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER'

export const FETCH_RECORDS_SUCCESS = 'FETCH_RECORDS_SUCCESS'
export const FETCH_RECORDS_FAILURE = 'FETCH_RECORDS_FAILURE'
