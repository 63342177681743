import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import Cookies from 'js-cookie'

Vue.use(VueAxios, axios)

const instance = axios.create({
    // TODO: Edit the url hereafter to configure Cors URL for backend API
    // baseURL: '/backend/public', // Test URL
    // baseURL: 'http://gurumandala.local:8000/', // Local URL
    // baseURL: 'http://v2b.gurumandala.local', // Local URL
    baseURL: 'https://api.gurumandala.lk', // Production URL
    // baseURL: 'http://127.0.0.1:8000/', // Production URL
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
})

instance.defaults.headers.common['Authorization'] = localStorage.getItem('token');

export default instance;

